/**
 * # Transition subsystem
 *
 * This module contains APIs related to a Transition.
 *
 * See:
 * - [[TransitionService]]
 * - [[Transition]]
 * - [[HookFn]], [[TransitionHookFn]], [[TransitionStateHookFn]], [[HookMatchCriteria]], [[HookResult]]
 *
 * @coreapi
 * @preferred
 * @module transition
 */ /** for typedoc */
export * from './interface';
export * from './hookBuilder';
export * from './hookRegistry';
export * from './rejectFactory';
export * from './transition';
export * from './transitionHook';
export * from './transitionEventType';
export * from './transitionService';

